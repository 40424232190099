import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { MA_APP_IDS, withMembersArea } from '@wix/members-area-integration-kit';
import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';

import loyaltyPageComponent from './components/LoyaltyPage/.component.json';
import { configureLoyaltyPageWidget, configureMyBusinessActions, EditorScriptContext } from './editor';

const _editorReady: EditorReadyFn = async (editorSDK, _appDefinitionId, { firstInstall, initialAppData }, flowAPI) => {
  const { httpClient } = flowAPI;
  const { applicationId } = initialAppData;

  if (firstInstall) {
    const pages = await editorSDK.document.pages.data.getAll('');
    const page = pages.find(({ tpaApplicationId }) => tpaApplicationId === applicationId);
    const pageRef = { id: page?.id!, type: 'DESKTOP' } as const;

    // Navigate to newly added page
    await editorSDK.pages.navigateTo('', { pageRef });

    // Rename page to match Loyalty Program name
    try {
      const loyaltyProgramRequest = await httpClient.request(getLoyaltyProgram({}));
      const loyaltyProgram = loyaltyProgramRequest.data.loyaltyProgram;

      if (loyaltyProgram?.name) {
        await editorSDK.document.pages.rename('', {
          title: loyaltyProgram.name,
          pageRef,
        });
      }
    } catch (e) {
      flowAPI.reportError(e as Error);
    }
  }
};

const _getAppManifest: GetAppManifestFn = ({ appManifestBuilder }, editorSDK, _contextParams, flowAPI) => {
  const context: EditorScriptContext = { editorSDK, flowAPI };

  appManifestBuilder.configureWidget(loyaltyPageComponent.id, (widgetBuilder) => {
    configureLoyaltyPageWidget(context, widgetBuilder);
  });

  appManifestBuilder.configureManagementActions((managementActionsBuilder) => {
    configureMyBusinessActions(context, managementActionsBuilder);
  });

  return appManifestBuilder.build();
};

export const { editorReady, getAppManifest, handleAction, onEvent, exports } = withMembersArea(
  {
    editorReady: _editorReady,
    getAppManifest: _getAppManifest,
    handleAction: async () => {},
    exports: () => {
      return {};
    },
  },
  {
    membersAreaApps: [MA_APP_IDS.MY_REWARDS],
  },
);
