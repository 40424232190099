import { ManagementActionsBuilder } from '@wix/yoshi-flow-editor';

import loyaltyPageConfig from '../../components/LoyaltyPage/.component.json';
import { BM_URL_INDEX_PAGE, BM_URL_MANAGE_EMAILS, BM_URL_MANAGE_PAGE, LOYALTY_OVERVIEW_HELP_ID } from '../constants';
import { EditorScriptContext } from '../types';

export const configureMyBusinessActions = (
  { flowAPI, editorSDK }: EditorScriptContext,
  managementActionsBuilder: ManagementActionsBuilder,
) => {
  const {
    translations: { t },
    environment: { appDefinitionId },
  } = flowAPI;

  managementActionsBuilder.mainActions().addAction({
    title: t('my-business-panel.view-dashboard'),
    icon: 'pageSelector',
    onClick: async () => {
      await editorSDK.editor.openDashboardPanel('', {
        url: BM_URL_INDEX_PAGE,
        closeOtherPanels: false,
      });
    },
  });

  managementActionsBuilder.customActions().addAction(
    {
      title: t('my-business-panel.manage-program'),
      icon: 'appManager_settingsAction',
      type: 'dashboard',
      onClick: async () => {
        await editorSDK.editor.openDashboardPanel('', {
          url: BM_URL_MANAGE_PAGE,
          closeOtherPanels: false,
        });
      },
    },
    {
      title: t('my-business-panel.manage-emails'),
      icon: 'email_icon',
      type: 'dashboard',
      onClick: async () => {
        await editorSDK.editor.openDashboardPanel('', {
          url: BM_URL_MANAGE_EMAILS,
          closeOtherPanels: false,
        });
      },
    },
    {
      title: t('my-business-panel.customize-loyalty-page'),
      icon: 'customizeDesignButtonBrush',
      type: 'editorActions',
      onClick: async () => {
        await editorSDK.editor.openSettingsPanel('', {
          appDefinitionId,
          widgetId: loyaltyPageConfig.id,
        });
      },
    },
  );

  managementActionsBuilder.learnMoreAction().set({
    id: LOYALTY_OVERVIEW_HELP_ID,
  });

  managementActionsBuilder.upgradeAction().set({
    upgradeType: 'SITE_UPGRADE',
    upgradeText: t('my-business-panel.upgrade-text'),
    upgradeLinkText: t('my-business-panel.upgrade-link-text'),
  });
};
